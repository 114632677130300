// @flow

import type { Action } from './state';

export const ActionTypeName = 'INITIAL';

export function createAction(): Action {
  return {
    type: ActionTypeName,
  };
}
