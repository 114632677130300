// @flow

import * as React from 'react';

export type SvgImageProps = {
  children?: React.Node,
  minX?: number,
  minY?: number,
  width?: number,
  height?: number,
  vWidth?: number,
  vHeight?: number,
};

export class SvgImage extends React.PureComponent<SvgImageProps, {}> {

  static +defaultProps = {
    minX: 0,
    minY: 0,
    width: 24,
    height: 24,
    vWidth: 24,
    vHeight: 24,
  };

  render() {
    const {
      minX = 0,
      minY = 0,
      width = 0,
      height = 0,
      vWidth = 0,
      vHeight = 0,
      children,
    } = this.props;

    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox={`${minX} ${minY} ${vWidth} ${vHeight}`}
      >
        { children }
      </svg>
    );
  }

}

export default SvgImage;
