// @flow

import React from 'react';
import type { StatelessFunctionalComponent } from 'react';
import { SvgImage } from '../svg/svg';
import s from './spinner.style';

const path: string = [
  'M8 0 v2',
  'C4.685 2 2 4.685 2 8',
  's2.685 6 6 6 6-2.685 6-6',
  'h2',
  'c0 4.416-3.584 8-8.008 8',
  'C3.576 16 0 12.416 0 8',
  's3.576-8 7.992-8',
  'H8z',
].join('');

type Props = {
  center?: boolean,
  size?: number,
}

export const Spinner: StatelessFunctionalComponent<Props> = ({ center, size }: Props) => (
  <s.Root center={center} size={size || 20}>
    <SvgImage
      width={size || 16}
      height={size || 16}
      vWidth={16}
      vHeight={16}
    >
      <s.Group>
        <path d={path} />
      </s.Group>
    </SvgImage>
  </s.Root>
);

export default Spinner;
