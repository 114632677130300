import { css } from 'styled-components';

export const ieOnly = styles => css`
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    ${styles}
  }
`;

export const hidden = css`
  position: absolute;

  height: 1px;
  width: 1px;
  padding: 0;
  margin: -1px;
  border: 0;
  clip: rect(0, 0, 0, 0);

  overflow: hidden;
`;

export const hideText = css`
  white-space: nowrap;
  text-indent: 100%;

  overflow: hidden;
`;

export const ellipsis = css`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const container = ({
  minWidth = 960,
  maxWidth = 1152,
  padding = 32,
} = {}) => {
  const doublePadding = padding * 2;

  return css`
    min-width: ${minWidth + doublePadding}px;
    max-width: ${maxWidth + doublePadding}px;
    width: 100%;
    margin: 0 auto;
    padding: 0 ${padding}px;
  `;
};

export const ifProp = (prop, value, failValue = '') => (props) => {
  if (!props[prop]) {
    return failValue;
  }

  return value;
};

export default {
  hidden,
  hideText,
  container,
  ifProp,
  ellipsis,
};
