// @flow

import { URL_PARAM_REDIRECT } from './web';

export class RoutePath {

  static +Root = '/';

  static +Agreement = '/agreement';

  static +BotCreate = '/bot';

  static +Bot = '/bot/:id';

  static +Template = '/template/:id';

  static +Bots = '/bots';

  static +Conversations = '/conversations';

  static +CustomBlocks = '/custom-blocks';

  static +CustomBlockCreate = '/custom-block';

  static +CustomBlock = '/custom-block/:id';

  static +AIBases = '/templates';

  static +AIBaseSpecific = '/templates/:type/:id';

  static +AIBaseCreate = '/templates/:id/template';

  static +Reports = '/reports';

  static +Report = '/reports/:id';

  static +Conversation = '/conversation/:id';

  static +Settings = '/settings';

  static +SettingsSubscriptions = '/settings/subscriptions';

  static +SettingsPayment = '/settings/payment';

  static +Users = '/users';

  static +UserLogin = '/user/login';

  static +UserLogout = '/user/logout';

  static +UserInvite = '/user/invite';

  static +UserAdd = '/user/add';

  static +UserProfile = '/user/profile';

  static +User = '/user/:id';

  static +UserEdit = '/user/edit/:id';

  static +UserPasswordCreate = '/invite';

  static +UserPasswordRecover = '/user/password/recover';

  static +UserPasswordReset = '/reset/password';

  static +PaymentCallback = '/payment/callback';

  static +apps = {

    docOne: {

      connect: '/apps/doc-one/connect',

    },

  };

  static pathAIBaseCreate(groupId: number): string {
    return `/templates/${groupId}/template`;
  }

  static pathAIBaseSpecific(id: number, type: string = 'regular'): string {
    return `/templates/${type}/${id}`;
  }

  static pathAgreementRedirect(location = window.location): string {
    const { pathname, search } = location;

    const urlParams = new URLSearchParams();
    urlParams.set(URL_PARAM_REDIRECT, `${pathname}${search}`);
    const urlRedirect = pathname !== this.Agreement
      ? `?${urlParams.toString()}`
      : '';

    return `${this.Agreement}${urlRedirect}`;
  }

  static pathUser(id: number): string {
    return `/user/${id}`;
  }

  static pathUserEdit(id: number): string {
    return `/user/edit/${id}`;
  }

  static pathBot(id: string): string {
    return `/bot/${id}`;
  }

  static pathCustomBlock(id: string | number): string {
    return `/custom-block/${id}`;
  }

  static pathTemplateBot(id: string): string {
    return `/template/${id}`;
  }

  static pathChat(botId: string): string {
    return `/chat/${botId}`;
  }

  static pathDialog(dialogId: string): string {
    return `/dialog/${dialogId}`;
  }

  static pathEmbedDialog(dialogId: string, options: ?string): string {
    return `/embed/dialog/${dialogId}${options || ''}`;
  }

  static pathWidgetDialog(dialogId: string, options: ?string): string {
    return `/widget/dialog/${dialogId}${options || ''}`;
  }

  static pathChatEmbed(botId: string): string {
    return `/embed/chat/${botId}`;
  }

  static pathChatWidget(botId: string, options?: string): string {
    return `/widget/chat/${botId}${options || ''}`;
  }

  static pathWidgetScript(): string {
    return '/static/js/widget.bundle.js';
  }

  static pathConversation(id: string): string {
    return `/conversation/${id}`;
  }

  static pathReport(id: string): string {
    return `/reports/${id}`;
  }

}

export default RoutePath;
