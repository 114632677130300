// @flow
import './polyfills';

import React from 'react';
import ReactDOM from 'react-dom';

import { GlobalStyle } from './global.style';
import ThemeProvider from './ui/theme/provider';

import { Start } from './start';

ReactDOM.render(
  <ThemeProvider>
    <GlobalStyle />
    <Start />
  </ThemeProvider>,
  document.getElementById('root'),
);
