// @flow

import i18n from 'i18next';
import backend from 'i18next-xhr-backend';
import { reactI18nextModule } from 'react-i18next';
import { DEFAULT_LOCALE } from './config';

// translations are already at
// '../public/locales/en/translation.json'
// which is the default for the xhr backend to load from

export const initLocales = async (product: string) => {
  await i18n
    .use(backend)
    .use(reactI18nextModule) // passes i18n down to react-i18next
    .init({
      preload: [DEFAULT_LOCALE],
      compatibilityJSON: 'v3',
      simplifyPluralSuffix: true,
      ns: ['features'],
      defaultNS: 'features',
      lng: DEFAULT_LOCALE,
      fallbackLng: DEFAULT_LOCALE, // use en if detected lng is not available
      interpolation: {
        escapeValue: false, // react already safes from xss
      },
      backend: {
        loadPath: `${process.env.PUBLIC_URL}/locales/${product}/{{lng}}/{{ns}}_{{lng}}.json`,
      },
      debug: false,
    });
};

export default i18n;
