// @flow

export type ConversationStatus = ''
  | 'completed'
  | 'interrupted'
  | 'in-process'
  | 'pause';

// eslint-disable-next-line
export class ConversationStatusName {

  static +empty = '';

  static +completed = 'completed';

  static +interrupted = 'interrupted';

  static +inProcess = 'in-process';

  static +pause = 'pause';

}
