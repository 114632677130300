// @flow

import { matchPath } from 'react-router';
import { buildQueryString } from '@form.one/utils';

import { RoutePath } from '../router/path';
import { WebPath } from '../router/web';
import * as Initial from '../redux/initial';

async function getStore() {
  if (getStore.store) {
    return getStore.store;
  }

  const storeImport = await import('../redux/store');
  getStore.store = storeImport.default;

  return getStore.store;
}

getStore.store = null;

export type HttpRequest = (...params: any[]) => Promise<any>;

export interface IHttp {
  get: HttpRequest;
  post: HttpRequest;
  put: HttpRequest;
  delete: HttpRequest;
  patch: HttpRequest;
  unfetch: (request: Promise<any>) => Promise<any>;
  parseResponse: (response: any) => any;
}

export type FetchMethod = 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH';

export type FetchCredentials = 'include';

export type FetchHeaders = { [key: string]: string };

export type FetchBody = {} | FormData | Blob | ArrayBuffer | string;

export type FetchOptions = {
  method: FetchMethod,
  headers?: FetchHeaders,
  credentials?: FetchCredentials,
  body?: FetchBody,
};

const BASE_URL = '/';

const requestHeadersForJsonData = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};

const buildUrl = (url, baseUrl) => {
  if (baseUrl === '/') {
    return url;
  }

  const segment = url.toLowerCase().split('://')[0];
  if (segment === 'http' || segment === 'https') {
    return url;
  }

  return url.substr(0, 1) === '/' ? baseUrl + url : `${baseUrl}/${url}`;
};

const isFormData = p => (typeof FormData !== 'undefined') && (p instanceof FormData);

const isGetOrDeleteMethod = (config: { method: string }) => ['GET', 'DELETE'].includes(config.method);

const needsQueryString = (config) => {
  const getOrDelete = isGetOrDeleteMethod(config);
  const hasData = !!config.body;
  return getOrDelete && hasData;
};

const request = (inputConfig) => {
  let config = {};
  config.headers = inputConfig.headers || {};
  config.body = inputConfig.data;
  config = { ...config, ...inputConfig };

  let queryString = '';
  if (needsQueryString(config)) {
    const query = buildQueryString(config.body);
    queryString = `?${query}`;
  } else if (!isFormData(config.body)) {
    config.body = JSON.stringify(config.body);
    config.headers = { ...config.headers, ...requestHeadersForJsonData };
  }

  const options: FetchOptions = {
    method: config.method,
    headers: config.headers,
    credentials: 'include',
  };

  if (!isGetOrDeleteMethod(config)) {
    options.body = config.body;
  }

  const url = buildUrl(config.url, BASE_URL) + queryString;
  return fetch(url, options);
};

const unfetchHandler = (response) => {
  const { ok } = response;
  return ok
    ? Promise.resolve(response)
    : Promise.reject(response);
};

// Используется для отлова 401 в чате/диалоге и перенаправления на АД
const botPaths = [
  RoutePath.Dialog,
  RoutePath.DialogEmbed,
  RoutePath.DialogWidget,
  RoutePath.Chat,
  RoutePath.ChatEmbed,
  RoutePath.ChatWidget,
];

const isBotPath = () => {
  const { pathname } = window.location;

  const isMatching = botPaths.some(path => matchPath(pathname, { path, exact: true }));
  return isMatching;
};

const CATCH_TO_REDIRECT = {
  401: async (response) => {
    const store = await getStore();
    const shouldUseForcedRedirect = isBotPath() && store.getState().instance.ADEnabled;

    if (shouldUseForcedRedirect) {
      window.location.replace(WebPath.user.signInAD(window.location.pathname));
      return Promise.reject(response);
    }

    store.dispatch(Initial.createAction());

    return Promise.reject(response);
  },

  451: (response) => {
    window.location.replace(RoutePath.pathAgreementRedirect());
    return Promise.reject(response);
  },
};

const handleUnauthorized = async (response) => {
  const { status } = response;
  const catchFunction = CATCH_TO_REDIRECT[status];

  if (catchFunction) {
    return catchFunction(response);
  }

  return Promise.reject(response);
};

const unfetch = (anyRequest: Promise<any>): Promise<any> => anyRequest
  .then(unfetchHandler)
  .catch(handleUnauthorized);

const parseResponse = (response: any) => response.json();

export const http: IHttp = {
  get: params => request({ ...params, method: 'GET' }),
  post: params => request({ ...params, method: 'POST' }),
  put: params => request({ ...params, method: 'PUT' }),
  delete: params => request({ ...params, method: 'DELETE' }),
  patch: params => request({ ...params, method: 'PATCH' }),
  unfetch,
  parseResponse,
};

export default http;
