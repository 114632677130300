// @flow

export class LocalStorage {

  static getItem(key: string) {
    let result;
    try {
      result = localStorage.getItem(key);
    } catch (e) {
      //
    }

    return result;
  }

  static setItem(key: string, value: string) {
    try {
      localStorage.setItem(key, value);
    } catch (e) {
      //
    }
  }

  static removeItem(key: string) {
    try {
      localStorage.removeItem(key);
    } catch (e) {
      //
    }
  }

  static clear() {
    try {
      localStorage.clear();
    } catch (e) {
      //
    }
  }

}

export default LocalStorage;
