// @flow

export const UCFirst = (input: string) => {
  if (!input) {
    return input;
  }

  const firstLetter = input.charAt(0).toUpperCase();
  const rest = input.slice(1);
  return `${firstLetter}${rest}`;
};

export default UCFirst;
