// @flow

import { http } from '../../http/http';
import { WebPath } from '../../router/web';
import { AbstractQueryThunk } from '../../http/abstractQuery.thunk';

export type FetchProductQueryParams = {};

type FetchProductResponse = {
  product: 'knowledge' | 'bot'
}

type FetchProductData = 'knowledge' | 'bot'

type FetchBasesError = {
  status: number
}

export class FetchProductThunk extends AbstractQueryThunk<FetchProductQueryParams, FetchProductData, FetchBasesError> {

  async fetch() {
    const request = http.get({
      url: WebPath.product(),
    });

    const response = await http.unfetch(request);
    return response;
  }

  getResponseSucceeded = async (response: any) => {
    const { product }: FetchProductResponse = await http.parseResponse(response);
    return product;
  };

}

export default FetchProductThunk;
