// @flow

import { EchoConnection } from './echoConnection';
import type { IConnectionChannel } from '../channel';

let connection: EchoConnection | null = null;

export async function getEchoConnectionChannel(channelName: string): Promise<IConnectionChannel> {
  if (!connection) {
    connection = new EchoConnection();
  }

  await connection.open();
  return connection.getConnectionChannel(channelName);
}

export default getEchoConnectionChannel;
