// @flow

type Activity = {
  type: string,
  cid: string,
}

class ActivityMetadataId {

  static +start = 'start';

  static +select = 'select';

}

const QA_ROOT_PROP = 'qa_test_id';

const isQaLocatorEnabled = process.env.REACT_APP_LOCATOR_ENABLE === 'true';

export function qaLocator(dataId: ?string) {
  const noNeedLocator = !dataId || !isQaLocatorEnabled;

  if (noNeedLocator) {
    return {};
  }

  return {
    'data-id': dataId,
  };
}

export function qaCreateLocatorElementName(elementId: ?string, rootId: ?string) {
  const hasName = rootId && elementId;

  if (hasName) {
    return `${rootId}__${elementId}`;
  }

  return null;
}

export function qaGetRootLocator(props: { [key: string]: any }) {
  return props[QA_ROOT_PROP];
}

export function qaLocatorElement(elementId: ?string, rootId: ?string) {
  if (!isQaLocatorEnabled) {
    return {};
  }

  const dataId = qaCreateLocatorElementName(elementId, rootId);
  return qaLocator(dataId);
}

export function qaLocatorElementProps(elementId: ?string, props: { [key: string]: any }) {
  if (!isQaLocatorEnabled) {
    return {};
  }

  const rootId = qaGetRootLocator(props);
  const dataId = qaCreateLocatorElementName(elementId, rootId);
  return qaLocator(dataId);
}

export function qaSetRootLocator(dataId: ?string) {
  const noNeedLocator = !dataId || !isQaLocatorEnabled;

  if (noNeedLocator) {
    return {};
  }

  return {
    [QA_ROOT_PROP]: dataId,
  };
}

export function qaRootLocatorElement(elementId: ?string, rootId: ?string) {
  if (!isQaLocatorEnabled) {
    return {};
  }

  const dataId = qaCreateLocatorElementName(elementId, rootId);
  return qaSetRootLocator(dataId);
}

export function qaRootLocatorElementProps(elementId: ?string, props: { [key: string]: any }) {
  if (!isQaLocatorEnabled) {
    return {};
  }

  const rootId = qaGetRootLocator(props);
  const dataId = qaCreateLocatorElementName(elementId, rootId);
  return qaSetRootLocator(dataId);
}

export function qaActivityLocator(activity: Activity, activities: Array<Activity>) {
  if (activity.type === ActivityMetadataId.start) {
    return qaLocator('start-block');
  }

  if (activity.type === ActivityMetadataId.select) {
    return qaLocator(activity.cid);
  }

  const activityIndex = activities.indexOf(activity);
  const prevActivity = activities[activityIndex - 1];
  return qaLocator(prevActivity.cid);
}
