// @flow

export function updateTitleTooltip(tooltipDom: Element, measureDom: Element, text: string) {
  if (!measureDom || !tooltipDom) {
    return;
  }

  const isContentOutOff =
    measureDom.scrollWidth > measureDom.clientWidth ||
    measureDom.scrollHeight > measureDom.clientHeight;

  isContentOutOff
    ? tooltipDom.setAttribute('title', text)
    : tooltipDom.removeAttribute('title');
}

export default updateTitleTooltip;
