// @flow
import * as React from 'react';
import { ThemeProvider } from 'styled-components';

import theme from './default';

export type Props = {
  children?: React.Node,
};

class Provider extends React.Component<Props, {}> {

  constructor() {
    super();
    this.state = {};
  }

  render() {
    return (
      <ThemeProvider theme={theme}>
        {this.props.children}
      </ThemeProvider>
    );
  }

}

export default Provider;
