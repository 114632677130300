// @flow

import Echo from 'laravel-echo';
import io from 'socket.io-client';

import { EchoConnectionChannel } from './echoConnectionChannel';
import type { IEchoConnectionChannel, IEchoConnection } from './echoChannel';
import { devLog } from '../../log';

window.io = io;

export class EchoConnection implements IEchoConnection {

  connectProgress: Promise<boolean> | null = null;

  connection: typeof Echo;

  channels: { [channelName: string]: IEchoConnectionChannel[] } = {};

  onConnect: () => void = () => {
    devLog('echoChannel: connected successfully');
  };

  onDisconnect: () => void = () => {
    devLog('echoChannel: the channel was disconnected forcibly');
  };

  open() {
    if (this.connectProgress) {
      return this.connectProgress;
    }

    this.connection = new Echo({
      broadcaster: 'socket.io',
      host: window.location.origin,
    });

    this.connection.connector.socket.on('connect', this.onConnect);
    this.connection.connector.socket.on('disconnect', this.onDisconnect);

    this.connectProgress = new Promise((resolve) => {
      const success = () => {
        this.connection.connector.socket.off(success);
        resolve(true);
      };

      this.connection.connector.socket.on('connect', success);
    });

    return this.connectProgress;

  }

  close() {
    this.connection?.disconnect?.();
    this.connectProgress = null;
  }

  closeChannel(channel: IEchoConnectionChannel) {
    if (channel.hasListeners() || !channel.isClosed()) {
      console.error('echoChannel: can`t remove chanel from store if has listeners or opened');
      return;
    }

    const channelName = channel.getName();
    this.channels[channelName] = this.channels[channelName].filter(channelInner => channelInner !== channel);

    if (!this.channels[channelName].length) {
      delete this.channels[channelName];
    }

    const hasNoChannels = !Object.keys(this.channels).length;
    if (hasNoChannels) {
      this.close();
    }
  }

  listen(channelName: string, event: string, callback: Function) {
    this.connection.channel(channelName).listen(event, callback);
  }

  unListen(channelName: string, event: string, callback: Function) {
    this.connection.channel(channelName).stopListening(event, callback);

    const hasListeners = this.channels[channelName].some(channel => channel.hasListeners());
    if (!hasListeners) {
      this.connection.leaveChannel(channelName);
    }
  }

  getConnectionChannel(channelName: string): IEchoConnectionChannel {
    const channel = new EchoConnectionChannel(channelName, this);
    this.channels[channelName] = this.channels[channelName] || [];
    this.channels[channelName].push(channel);

    return channel;
  }

}

export default EchoConnection;
