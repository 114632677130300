// @flow

import uuid from 'uuid';
import type { Dispatch } from 'redux';

import { http } from './http';
import type IQueryThunk from './iQuery.thunk';

export class AbstractQueryThunk<QueryParams, Data, Error> implements IQueryThunk<QueryParams, Data, Error> {

  requestId: string;

  dispatch: Dispatch<*>;

  params: QueryParams;

  getState: () => ({});

  constructor(params: QueryParams) {
    this.requestId = uuid();
    this.params = params;
  }

  setDispatch(dispatch: Dispatch<*>) {
    this.dispatch = dispatch;
    dispatch((innerDispatch: Dispatch<*>, getState: () => void) => {
      this.getState = getState;
    });
  }

  createAction() {
    return (dispatch: Dispatch<*>, getState: () => void) => {
      this.getState = getState;
      this.dispatch = dispatch;
      this.dispatchQuery();
      this.invoke().then(
        this.dispatchQuerySucceeded,
        this.dispatchQueryFailed,
      );
    };
  }

  async createInvoke(dispatch: Dispatch<*>): Promise<Data> {
    this.setDispatch(dispatch);
    this.dispatchQuery();

    try {
      const expectedData = await this.invoke();
      this.dispatchQuerySucceeded(expectedData);
      return expectedData;
    } catch (errorData) {
      this.dispatchQueryFailed(errorData);
      throw errorData;
    }
  }

  dispatchQuery = () => {};

  // eslint-disable-next-line no-unused-vars
  dispatchQuerySucceeded = (data: Data) => {};

  // eslint-disable-next-line no-unused-vars
  dispatchQueryFailed = (data: Error) => {};

  async invoke(): Promise<Data> {
    try {
      const response = await this.fetch();
      return await this.getResponseSucceeded(response);
    } catch (e) {
      throw await this.getResponseFailed(e);
    }
  }

  /**
   * Abstract method
   */
  async fetch(): Promise<any> {
    throw new Error('implement abstract method fetch');
  }

  /**
   * Abstract method
   */
  getResponseSucceeded = async (response: any): Promise<Data> => {
    throw new Error('implement abstract method mapQueryItem', response);
  };

  getResponseFailed = async (response: any): Promise<Error> => {
    const { status, ok } = response;
    let dataResponse = {};
    try {
      dataResponse = await http.parseResponse(response);
    } catch (e) {
      console.error(e);
    }

    return {
      ok,
      status,
      ...dataResponse,
    };
  };

}

export default AbstractQueryThunk;
