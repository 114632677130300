// @flow

import styled, { keyframes, css } from 'styled-components';

const styles = {};

const animation = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

styles.Root = styled.div`
  width: ${props => props.size + 4}px;
  height: ${props => props.size + 4}px;
  padding: 4px;
  ${props => props.center && css`
    margin: auto;
    display: block;
    vertical-align: middle;
  `}
`;

styles.Group = styled.g`
  fill: ${props => props.theme.colors.blue};
  fill-rule: evenodd;
  transform-origin: 8px 8px;
  animation: 4s linear infinite ${animation};
`;

export default styles;
