// @flow
import compact from 'lodash/compact';

const prefix = '/web/';

export type ServiceRequest = {
  service: string,
  correlationId?: string,
  cid?: string,
};

export const URL_PARAM_REDIRECT = 'redirect_uri';

export class WebPath {

  static product() {
    return `${prefix}product`;
  }

  static sessionId() {
    return `${prefix}uid`;
  }

  /**
   * It is used to start a new conversation using the chat form.
   * @see FRO-743
   */
  static newSessionId() {
    return `${prefix}uid/new`;
  }

  static botById(id: string) {
    return `${prefix}bot/${id}`;
  }

  static botVertex(page: number = 1) {
    return `${prefix}bot/vertex?page=${page}`;
  }

  static botVersions(id: string, page: number) {
    return `${prefix}bot/${id}/history?page=${page}`;
  }

  static botByIdVersions(id: string) {
    return `${prefix}bot/history/${id}`;
  }

  static botSave() {
    return `${prefix}bot`;
  }

  static botDesignOptionsSave(botId: number) {
    return `${prefix}bot/${botId}/options/design`;
  }

  static botSettingsSave(botId: number) {
    return `${prefix}bot/${botId}/options/settings`;
  }

  static botMessengerTokenSave(botId: number, messengerType: string) {
    return `${prefix}bot/${botId}/messenger/${messengerType}`;
  }

  static botsQuery() {
    return `${prefix}bot/list`;
  }

  static testScript() {
    return `${prefix}bot/script/run`;
  }

  /**
   * Provides the url to publish the bot.
   * @param id - the bot id
   * @returns {string} - url
   */
  static botPublish(id: string) {
    return `${prefix}bot/${id}/publish`;
  }

  /**
   * Provides the url to unpublish the bot.
   * @param id - the bot id
   * @returns {string} - url
   */
  static botUnpublish(id: string) {
    return `${prefix}bot/${id}/unpublish`;
  }

  /**
   * Provides the url to send a message to the chat preview.
   * @param id - the bot id
   * @returns {string} - url
   */
  static previewChatSend(id: string) {
    return `${prefix}send/${id}`;
  }

  /**
   * Provides the url to start the chat preview.
   * @param botId - the bot id
   * @returns {string} - url
   */
  static previewChatStart(botId: string) {
    return `${prefix}start/${botId}`;
  }

  /**
   * Provides the url to load the chat info.
   * @param botId - the bot id
   * @returns {string} - url
   */
  static chatBot(botId: string) {
    return `${prefix}chat/${botId}`;
  }

  /**
   * Provides the url to start the chat.
   * @param botId - the bot id
   * @returns {string} - url
   */
  static chatStart(botId: string) {
    return `${prefix}chat/${botId}/start`;
  }

  /**
   * Provides the url to send a message to the bot.
   * @param botId - the bot id
   * @returns {string} - url
   */
  static chatSend(botId: string) {
    return `${prefix}chat/${botId}/send`;
  }

  static chatUpload() {
    return `${prefix}chat/file`;
  }

  static imageUpload() {
    return `${prefix}bot/image`;
  }

  static imageDownload(id: string) {
    return `${prefix}image/${id}.png`;
  }

  static fileUpload() {
    return `${prefix}file`;
  }

  static fileDownload(id: string) {
    return `${prefix}file/${id}`;
  }

  static +dialog = {

    create(botId: string) {
      return `${prefix}dialog/${botId}/create`;
    },

    info(dialogId: string) {
      return `${prefix}dialog/${dialogId}`;
    },

    bot(botId: string) {
      return `${prefix}dialog/bot/${botId}`;
    },

    history(dialogId: string) {
      return `${prefix}dialog/${dialogId}/history`;
    },

    start() {
      return `${prefix}dialog/start`;
    },

    updateLastMessage(dialogId: string) {
      return `${prefix}dialog/${dialogId}/lastReadItem`;
    },

    sendMessage(dialogId: string) {
      return `${prefix}dialog/${dialogId}/sendMessage`;
    },

    sendFile() {
      return `${prefix}dialog/file`;
    },

    sendFormFile(dialogId: string) {
      return `${prefix}dialog/${dialogId}/file`;
    },

  };

  static +template = {

    templates(botId: number) {
      return `${prefix}template/${botId}/bots`;
    },

    bots() {
      return `${prefix}template/source/botList`;
    },

    groupsAccess(baseId: number) {
      return `${prefix}template/${baseId}/access/groups`;
    },

    usersAccess(baseId: number) {
      return `${prefix}template/${baseId}/access/users`;
    },

    sources(baseId: number) {
      return `${prefix}template/${baseId}/sources`;
    },

    startTraining(baseId: number) {
      return `${prefix}template/${baseId}/runProcessing`;
    },

    source(baseId: number, id?: number) {
      const href = `${prefix}template/${baseId}/source`;
      return compact([href, id]).join('/');
    },

    byId(id: number) {
      return `${prefix}template/bot/${id}`;
    },

    versions(id: string, page: number = 1) {
      return `${prefix}template/bot/${id}/history?page=${page}`;
    },

    byIdVersions(id: string) {
      return `${prefix}template/bot/history/${id}`;
    },

    byGroup(groupId: number) {
      return `${prefix}template/${groupId}/bot`;
    },

    gotoBots() {
      return `${prefix}template/bot/goto/bots`;
    },

    gotoActivities(botId: number) {
      return `${prefix}template/bot/goto/bots/${botId}/activities`;
    },

    settings(id: number) {
      return `${prefix}template/bot/${id}/options/settings`;
    },

    keywordsByBot(id: number, page: ?number) {
      return `${prefix}template/bot/${id}/keywords${page ? `?page=${page}` : ''}`;
    },

    addKeyword(id: number) {
      return `${prefix}template/bot/${id}/keyword`;
    },

    keyword(id: number) {
      return `${prefix}template/keyword/${id}`;
    },

  };

  static +templateGroup = {

    create() {
      return `${prefix}template`;
    },

    group(id: number) {
      return `${prefix}template/${id}`;
    },

    groups() {
      return `${prefix}templates`;
    },

    templates(id: string, params: { page?: number, title?: string } = {}) {
      const tail = Object.entries(params).map(([key, value]) => `${key}=${value}`).join('&');
      return `${prefix}template/${id}/bots${tail ? `?${tail}` : ''}`;
    },

    groupsWithoutPagination() {
      return `${prefix}template/bot/templates`;
    },

    keywords(id: number, page?: number) {
      return `${prefix}template/${id}/keywords${page ? `?page=${page}` : ''}`;
    },

    addKeyword(id: number) {
      return `${prefix}template/${id}/keyword`;
    },

    processBulk(id: number) {
      return `${prefix}template/${id}/keyword/bulk`;
    },

  };

  static +preview = {

    create(botId: string) {
      return `${prefix}dialog/${botId}/create/preview`;
    },

    activities(botVersionId: string) {
      return `${prefix}preview/${botVersionId}/activities`;
    },

    start() {
      return `${prefix}preview/start`;
    },

    pause(dialogId: string) {
      return `${prefix}preview/${dialogId}/pause`;
    },

    close(dialogId: string) {
      return `${prefix}preview/${dialogId}/close`;
    },

    sendMessage(dialogId: string) {
      return `${prefix}preview/${dialogId}/sendMessage`;
    },

  };

  static +bot = {
    bots() {
      return `${prefix}bot/goto/bots`;
    },

    variablesData(botVersionId: number) {
      return `${prefix}bot/${botVersionId}/dataVariables`;
    },

    variables(botVersionId: string | number) {
      return `${prefix}bot/${botVersionId}/variables`;
    },

    activities(botId: number) {
      return `${prefix}bot/goto/bots/${botId}/activities`;
    },

    users() {
      return `${prefix}bot/users`;
    },

    emailNotificationUsers() {
      const query = `${prefix}bot/email-notification/users`;

      return query;
    },

    catchUpMessages(botId: number) {
      return `${prefix}bot/${botId}/catch-up-messages`;
    },

    catchUpMessage(botId: number, id: number) {
      return `${prefix}bot/${botId}/catch-up-messages/${id}`;
    },

    operators() {
      return `${prefix}bot/operators`;
    },

  };

  static +conversations = {

    query() {
      return `${prefix}conversations`;
    },

    assignees() {
      return `${prefix}conversations/assignees`;
    },

    exportXlsxAll() {
      return `${prefix}conversations/export/xlsx/all`;
    },

    exportXlsx() {
      return `${prefix}conversations/export/xlsx`;
    },

  };

  static +customBlock = {
    categories() {
      return `${prefix}vertex/category`;
    },

    vertex() {
      return `${prefix}vertex`;
    },

    block(id: number) {
      return `${prefix}vertex/${id}`;
    },

    download(id: number) {
      return `${prefix}vertex/${id}/file`;
    },

    install(id: number) {
      return `${prefix}vertex/${id}/install`;
    },

    uninstall(id: number) {
      return `${prefix}vertex/${id}/uninstall`;
    },

    update(id: number) {
      return `${prefix}vertex/${id}/update`;
    },

    bots(id: number) {
      return `${prefix}vertex/${id}/bots`;
    },

  };

  static +conversation = {

    updateAssignee(id: number) {
      return `${prefix}conversation/${id}/assignee`;
    },

    users() {
      return `${prefix}conversation/users`;
    },

    conversations() {
      return `${prefix}conversation/conversations`;
    },

    fetch(id: string) {
      return `${prefix}conversation/${id}`;
    },

    fetchAnswers(id: string) {
      return `${prefix}conversation/${id}/answers`;
    },

    fetchFiles(id: string) {
      return `${prefix}conversation/${id}/files`;
    },

    updateLastMessage(id: string) {
      return `${prefix}conversation/${id}/chat/lastReadItem`;
    },

    downloadAllFiles(id: string) {
      return `${prefix}conversation/${id}/files/all`;
    },

    fetchChatHistory(id: string) {
      return `${prefix}conversation/${id}/chat`;
    },

    /**
     * Provides the url to send a message to the chat by the operator.
     * @param id - the conversation id
     * @returns {string} - url
     */
    chatSend(id: string) {
      return `${prefix}conversation/${id}/chat/send`;
    },

    chatCommandSend(id: string) {
      return `${prefix}conversation/${id}/command`;
    },

    /**
     * Provides the url to pause the chat by the operator.
     * @param id - the conversation id
     * @returns {string} - url
     */
    chatPause(id: string) {
      return `${prefix}conversation/${id}/chat/pause`;
    },

    /**
     * Provides the url to play the chat by the operator.
     * @param id - the conversation id
     * @returns {string} - url
     */
    chatPlay(id: string) {
      return `${prefix}conversation/${id}/chat/play`;
    },

    /**
     * Provides the url to play the chat by the operator.
     * @returns {array} - items
     */
    commands() {
      return `${prefix}conversation/commands`;
    },

    /**
     * Provides the url to play the chat by the operator.
     * @param id - the conversation id
     * @returns {string} - command
     */
    command(id: string) {
      return `${prefix}conversation/${id}/command`;
    },

  };

  static +user = {

    processBulk() {
      return `${prefix}user/bulk`;
    },

    current() {
      return `${prefix}current/user`;
    },

    signInAD(redirectURL = '') {
      const baseUrl = `${prefix}login/ad`;
      const tail = redirectURL ? `?${URL_PARAM_REDIRECT}=${redirectURL}` : '';
      return `${baseUrl}${tail}`;
    },

    signIn() {
      return `${prefix}user/login`;
    },

    signOut() {
      return `${prefix}user/logout`;
    },

    inviteSend() {
      return `${prefix}invite/send`;
    },

    inviteResend() {
      return `${prefix}invite/resend`;
    },

    inviteCancel() {
      return `${prefix}invite/cancel`;
    },

    passwordCreate() {
      return `${prefix}invite/check`;
    },

    passwordRecover() {
      return `${prefix}password/email`;
    },

    passwordReset() {
      return `${prefix}password/reset`;
    },

    checkToken() {
      return `${prefix}check/token`;
    },

    users() {
      return `${prefix}user`;
    },

    usersAll() {
      return `${prefix}user/all`;
    },

    block() {
      return `${prefix}user/block`;
    },

    unblock() {
      return `${prefix}user/unblock`;
    },

    get(id: number) {
      return `${prefix}user/${id}`;
    },

    update(id: number) {
      return `${prefix}user/${id}`;
    },

  };

  static +group = {
    create() {
      return `${prefix}user/group`;
    },

    list() {
      return `${prefix}user/groups`;
    },

    listAll() {
      return `${prefix}user/groups/all`;
    },

    item(id: number) {
      return `${prefix}user/group/${id}`;
    },
  };

  static +apps = {

    apps() {
      return `${prefix}apps`;
    },

    connect(name: string) {
      return `${prefix}apps/${name}/connect`;
    },

    authUrl(appName: string) {
      return `${prefix}apps/authorization_url/${appName}`;
    },

    getPaymentUrl(dialogId: string, paymentService: string, returnUrl: string) {
      return `${prefix}apps/${paymentService}/${dialogId}/payment-url?returnUrl=${encodeURIComponent(returnUrl)}`;
    },

    docOne: {

      templates() {
        return `${prefix}apps/doc-one/templates`;
      },

      template() {
        return `${prefix}bot/activity/doc-one/template`;
      },

    },

    stripe: {

      payPreview() {
        return `${prefix}apps/stripe/pay/preview`;
      },

      pay() {
        return `${prefix}apps/stripe/pay`;
      },

      getKey(userId: number) {
        return `${prefix}apps/stripe/key/${userId}`;
      },

      products() {
        return `${prefix}apps/stripe/products`;
      },
    },

  };

  static +caseOne = {
    apps: {
      modules() {
        return `${prefix}apps/case-one`;
      },

      module(id) {
        return `${prefix}apps/case-one/${id}`;
      },

      connect(id) {
        return `${prefix}apps/case-one/${id}/connect`;
      },

      disconnect(id) {
        return `${prefix}apps/case-one/${id}/disconnect`;
      },
    },

    getInstanceItems() {
      return `${prefix}bot/activity/case-one/modules`;
    },

    getObjectTypeItems(versionId, activityCid) {
      return `${prefix}bot/${versionId}/activity/case-one/${activityCid}/object-types`;
    },

    field: {
      name(versionId, activityCid) {
        return `${prefix}bot/${versionId}/activity/case-one/${activityCid}/object-fields`;
      },

      requiredFields(versionId, activityCid) {
        return `${prefix}bot/${versionId}/activity/case-one/${activityCid}/required-fields`;
      },

      value(versionId, activityCid, fieldId) {
        return `${prefix}bot/${versionId}/activity/case-one/${activityCid}/field/${fieldId}/values`;
      },
    },

    filter: {
      parameter(versionId, activityCid) {
        return `${prefix}bot/${versionId}/activity/case-one/${activityCid}/fields`;
      },

      value(versionId, activityCid, fieldId) {
        return `${prefix}bot/${versionId}/activity/case-one/${activityCid}/filter/${fieldId}/field-values`;
      },
    },
  };

  static +billing = {

    fetch() {
      return `${prefix}billing`;
    },

  };

  static +subscription = {

    fetch() {
      return `${prefix}subscriptions`;
    },

    current() {
      return `${prefix}subscription/current`;
    },

  };

  static +payment = {

    current() {
      return `${prefix}payment`;
    },

  };

  static +instance = {

    current() {
      return `${prefix}instance`;
    },

    update() {
      return `${prefix}settings`;
    },

  };

  static +services = {

    getAuthUrl(req: ServiceRequest) {
      if (req.correlationId && req.cid) {
        return `${prefix}services/authorization_url/${req.service}/${req.correlationId}/${req.cid}`;
      }

      return null;
    },

  };

  static +analytic = {

    getKey() {
      return `${prefix}apps/analytic/key`;
    },

  };

  static +reports = {

    list(page: number) {
      return `${prefix}reports?page=${page}`;
    },

  };

  static +report = {

    data(id: number) {
      return `${prefix}reports/${id}/data`;
    },

    metadata(id: number) {
      return `${prefix}reports/${id}`;
    },

    exportCSV(id: number) {
      return `${prefix}reports/${id}/export/csv`;
    },

    exportXLSX(id: number) {
      return `${prefix}reports/${id}/export/xlsx`;
    },

  };

  static +ai = {

    retrain() {
      return `${prefix}bot/ai/retrain/run`;
    },

    cancelRetrain() {
      return `${prefix}bot/ai/retrain/cancel`;
    },

  };

}

export default WebPath;
