// @flow

export function devLog(...argsInput) {
  if (process.env.NODE_ENV !== 'development') {
    return;
  }

  const args = [
    '📙',
    ...Array.from(argsInput),
  ];

  console.log.apply(this, args);
}

export default devLog;
