const theme = {
  colors: {
    black: '#000',
    black10: 'rgba(0, 0, 0, 0.1)',
    black09: 'rgba(0, 0, 0, 0.9)',
    black15: 'rgba(0, 0, 0, 0.15)',
    black13: 'rgba(0, 0, 0, 0.13)',
    brightBlue: '#006cff',
    transparent: 'transparent',
    blue03: 'rgba(21, 127, 243, 0.03)',
    blue03solid: '#f8fbfe',
    brightBlue5: 'rgba(0, 108, 255, 0.05)',
    circle: '#c8cccf',
    gray: '#d8d8d8',
    coolGray: '#a6b1ba',
    dark: '#22313f',
    dark05: 'rgba(34, 49, 63, 0.05)',
    dark10: 'rgba(34, 49, 63, 0.1)',
    deepSkyBlue: '#157ff3',
    white: '#ffffff',
    whiteTwo: '#f8f8f8',
    whiteThree: '#f2f2f2',
    blue: '#157ff3',
    botMessage: '#eef0f2',
    typingDot: '#6C7A89',
    hoveredGray: '#707983',
    defaultTextColor: '#22313f',
    blueColor: '#4183d7',
    greenColor: '#59b671',
    redColor: '#d0021b',
    redLightColor: '#e2374c',
    redLightColor01: 'rgba(226, 55, 76, 0.1)',
    errorColor: '#e74c3c',
    yellowColor: '#fdc129',
    lightGrayColor: '#f3f3f3',
    mdGrayColor: '#9098a0',
    mercuryHex: '#9aa0a6',
    mercuryHex04: 'rgba(154, 160, 166, 0.4)',
    darkGrayColor: '#57616c',
    silverColor: '#a6b1ba',
    stoneColor: '#6c7a89',
    whiteColor: '#ffffff',
    globalLinesColor: '#dadfe1',
    obsidian: '#202124',
    invalid: '#ff6400',
    inputBackground: '#f4f9ff',
    errorBackground: '#fff7f5',
  },
  fonts: {
    roboto: 'Roboto',
    robotoRegular: 'Roboto-Regular',
    robotoMedium: 'Roboto-Medium',
    robotoBold: 'Roboto-Bold',
    RobotoMonoRegular: 'RobotoMono-Regular',
    RobotoMonoMedium: 'RobotoMono-Medium',
  },
  sizes: {
    1: '4px',
    2: '8px',
    3: '12px',
    4: '16px',
    5: '24px',
    6: '32px',
    7: '48px',
  },
};

export default theme;
