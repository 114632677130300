// @flow

export const RESTRICTION_MODES = {
  ALL_FILES: 'all',
  SELECTED_FORMATS: 'selectedFormats',
};

export const getAcceptedFileFormats = (restrictions: FileUploadRestrictions) => {
  if (!restrictions) {
    return undefined;
  }

  return restrictions?.uploadRestrictionMode === RESTRICTION_MODES.SELECTED_FORMATS
    ? restrictions?.allowedFileFormats?.map?.((value: string) => `.${value}`).join(',')
    : undefined;
};
