// @flow

export type RGB = {
  r: number;
  g: number;
  b: number;
}

export type HSL = {
  h: number;
  s: number;
  l: number;
}

export class Color {

  static hex2rgb(hex: string): RGB {
    return {
      r: parseInt(hex.substr(1, 2), 16),
      g: parseInt(hex.substr(3, 2), 16),
      b: parseInt(hex.substr(5, 2), 16),
    };
  }

  static hex2rgba(hex: string, opacity: number = 1): string {
    const rgb = Color.hex2rgb(hex);
    return `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, ${opacity})`;
  }

  static rgbToHsl({ r, g, b }: RGB): HSL {
    r /= 255;
    g /= 255;
    b /= 255;

    const max = Math.max(r, g, b);
    const min = Math.min(r, g, b);
    let h = 0;
    let s = 0;
    let l = (max + min) / 2;

    if (max !== min) {
      const d = max - min;
      s = l > 0.5
        ? d / (2 - max - min)
        : d / (max + min);

      switch (max) {
        case r: h = (g - b) / d + (g < b ? 6 : 0); break;
        case g: h = (b - r) / d + 2; break;
        case b: h = (r - g) / d + 4; break;
        default: break;
      }

      h /= 6;
    }

    h = Math.round(h * 360);
    s = Math.round(s * 100);
    l = Math.round(l * 100);

    return { h, s, l };
  }

  static hex2ToHsl(hex: string): HSL {
    const rgb = Color.hex2rgb(hex);
    return Color.rgbToHsl(rgb);
  }

}

export default Color;
