// @flow
import {
  DependencyList,
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react';

export const useStatePrevious = <S>(
  initialState: (() => S),
  dependencyList: DependencyList,
): [S, Dispatch<SetStateAction<S>>] => {

  const [value, setValue] = useState<S>(initialState);
  const refInitialFn = useRef(initialState);

  useEffect(() => {
    refInitialFn.current = initialState;
  });

  useEffect(() => {
    setValue(refInitialFn.current());
  }, dependencyList);

  return [value, setValue];
};

export default useStatePrevious;
