// @flow

import { createGlobalStyle } from 'styled-components';

// Global style
// eslint-disable-next-line no-unused-expressions
export const GlobalStyle = createGlobalStyle`
  * {
    -webkit-font-smoothing: antialiased;
    box-sizing: border-box;
  }

  body,
  input,
  optgroup,
  textarea,
  select,
  button {
    font-family: Roboto-Regular, pragmatica, Helvetica, Arial, sans-serif;
  }

  html,
  body,
  div#root {
    height: 100%;
    margin: 0;
    padding: 0;
  }

  @keyframes onAutoFillStart {
    from { /* empty */ }
    to { /* empty */ }
  }

  @keyframes onAutoFillCancel {
    from { /* empty */ }
    to { /* empty */ }
  }

  input:-webkit-autofill {
    animation-name: onAutoFillStart;
    transition: background-color 50000s ease-in-out 0s;
  }

  input:not(:-webkit-autofill) {
    animation-name: onAutoFillCancel;
  }
`;

export default GlobalStyle;
