// @flow

import MarkdownIt from 'markdown-it';

const md = new MarkdownIt();

export const markdownToHtml = (markdown: string): string => md.render(markdown);

export const stripHtmlTags = (html: string): string => html.replace(/<\/?[^>]+>/gi, '');

export default markdownToHtml;
