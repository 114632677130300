// @flow

export const DialogSourceEnum = {
  web: 'Web',
  iframe: 'iFrame',
  widget: 'Widget',
  api: 'API',
  telegram: 'Telegram',
  unknown: 'Unknown',
};

export type DialogSource = $Values<DialogSourceEnum>;

export default DialogSourceEnum;
