// @flow

const POSSIBLE_VISIBILITY_ERROR = 15; // допустимая погрешность, при которой элемент считается видимым

export function isDescendant(parent: Node, child: Node): boolean {
  let node: ?Node = child.parentNode;

  while (node) {
    if (node === parent) {
      return true;
    }

    node = node.parentNode;
  }

  return false;
}

export function offset(el: HTMLElement): {| top: number, left: number |} {
  const rect = el.getBoundingClientRect();

  const { body } = document;
  if (!body) {
    return {
      top: 0,
      left: 0,
    };
  }

  return {
    top: rect.top + body.scrollTop,
    left: rect.left + body.scrollLeft,
  };
}

export const isVisible = (DOMNode: HTMLElement, container: HTMLElement) => {
  if (!DOMNode || !container) {
    return false;
  }

  const itemRect = DOMNode.getBoundingClientRect();
  const itemTopBorder = itemRect.top;
  const itemBottomBorder = (itemRect.top + itemRect.height) - POSSIBLE_VISIBILITY_ERROR;
  const containerRect = container.getBoundingClientRect();
  const containerTopBorder = containerRect.top;
  const containerBottomBorder = containerRect.top + containerRect.height;
  const topBorderInViewport = itemTopBorder > containerTopBorder
    && itemTopBorder < containerBottomBorder;

  const bottomBorderInViewport = itemBottomBorder >= containerTopBorder
    && itemBottomBorder <= containerBottomBorder;

  return topBorderInViewport && bottomBorderInViewport;
};

const isAboveContainerBottom = (DOMNode: HTMLElement, container: HTMLElement): boolean => {
  if (!DOMNode || !container) {
    return false;
  }

  const containerRect = container.getBoundingClientRect();
  const containerBottomBorder = containerRect.top + containerRect.height;
  const itemRect = DOMNode.getBoundingClientRect();
  const itemBottomBorder = (itemRect.top + itemRect.height) - POSSIBLE_VISIBILITY_ERROR;
  const spaceFromBottom = itemBottomBorder - containerBottomBorder;

  return spaceFromBottom < 0;
};

export const getLastVisibleChildren = (container: HTMLElement, DOMNodes: HTMLElement[]): HTMLElement => {
  if (!DOMNodes || !DOMNodes.length || !container) {
    return null;
  }

  const firstItemIndex = 0;
  const lastItemIndex = DOMNodes.length - 1;
  const lastItem = DOMNodes[lastItemIndex];
  const lastItemIsVisible = isVisible(lastItem, container);
  if (lastItemIsVisible) {
    return lastItem;
  }

  if (DOMNodes.length < 3) {
    const [lastVisibleItem] = [...DOMNodes]
      .filter(item => isVisible(item, container))
      .reverse();

    return lastVisibleItem;
  }

  const midItemIndex = parseInt((lastItemIndex - firstItemIndex) / 2, 10);
  const midItem = DOMNodes[midItemIndex];
  let nextDOMNodes = [];
  if (isAboveContainerBottom(midItem, container)) {
    nextDOMNodes = DOMNodes.slice(midItemIndex);
  } else {
    nextDOMNodes = DOMNodes.slice(firstItemIndex, midItemIndex);
  }

  return getLastVisibleChildren(container, nextDOMNodes);
};

// export default {
//   isDescendant,
//   offset,
//   getLastVisibleChildren,
// };
