// @flow

import isFunction from 'lodash/isFunction';
import has from 'lodash/has';

export function setRefValue(
  dom: HTMLElement | null,
  ref: { current: null | HTMLElement } | (dom: HTMLElement) => void,
) {
  if (isFunction(ref)) {
    ref(dom);
    return;
  }

  if (has(ref, 'current')) {
    ref.current = dom;
  }
}

export default setRefValue;
