// @flow

export const buildQueryString = (params, prefix = '') => {
  if (Array.isArray(params)) {
    return params.map((param) => {
      const key = encodeURIComponent(`${prefix}[]`);
      return `${key}=${encodeURIComponent(param)}`;
    }).join('&');
  }

  if (typeof params === 'object') {
    const keys = Object.keys(params);
    return keys
      .map((param) => {
        if (params[param] && typeof params[param] === 'object') {
          return buildQueryString(params[param], prefix ? `${prefix}[${param}]` : param);
        }

        const key = encodeURIComponent(prefix ? `${prefix}[${param}]` : param);
        const value = encodeURIComponent(params[param] === null ? '' : params[param]);
        return `${key}=${value}`;
      })
      .join('&');
  }

  return params;
};

export default buildQueryString;
