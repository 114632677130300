// @flow

export const isElementVisible = (el: HTMLLIElement) => {
  const rect = el.getBoundingClientRect();
  const vWidth = window.innerWidth || document.documentElement.clientWidth;
  const vHeight = window.innerHeight || document.documentElement.clientHeight;

  const outOfViewPort =
    rect.right < 0 ||
    rect.bottom < 0 ||
    rect.left > vWidth ||
    rect.top > vHeight;

  if (outOfViewPort) {
    return false;
  }

  const diff = 5;

  const elementItselfFromPoint =
    el.contains(document.elementFromPoint(rect.left + diff, rect.top + diff)) &&
    el.contains(document.elementFromPoint(rect.right - diff, rect.top + diff)) &&
    el.contains(document.elementFromPoint(rect.right - diff, rect.bottom - diff)) &&
    el.contains(document.elementFromPoint(rect.left + diff, rect.bottom - diff));

  return elementItselfFromPoint;

};

export default isElementVisible;
