// @flow

import type { IEchoConnectionChannel, IEchoConnection } from './echoChannel';
import { devLog } from '../../log';

type ListenerEvents = { [event: string]: Function[] }

export class EchoConnectionChannel implements IEchoConnectionChannel {

  isOpen: boolean = true;

  +name: string;

  connection: IEchoConnection;

  listeners: ListenerEvents = {};

  constructor(name: string, connection: IEchoConnection) {
    this.name = name;
    this.connection = connection;
  }

  getName(): string {
    return this.name;
  }

  listen(event: string, callback: Function): this {
    if (!this.isOpen) {
      console.error('echoChannel: The channel is closed. Create new channel');
      return this;
    }

    this.listeners[event] = this.listeners[event] || [];

    const existCallback = this.listeners[event].find(innerCallback => callback === innerCallback);
    if (existCallback) {
      return this;
    }

    this.listeners[event].push(callback);

    this.connection.listen(this.name, event, callback);

    return this;
  }

  hasListeners(): boolean {
    return Object.keys(this.listeners).length > 0;
  }

  unListen(event: string, callback: Function): this {
    if (!callback) {
      console.error('echoChannel: unListen mast must have a callback function');
    }

    if (!this.listeners?.[event]?.length) {
      devLog('echoChannel: unListen has no listen');
    }

    this.listeners[event] = (this.listeners[event]).filter(innerCallback => callback !== innerCallback);
    if (!this.listeners[event].length) {
      delete this.listeners[event];
    }

    this.connection.unListen(this.name, event, callback);

    return this;
  }

  close: () => void = () => {
    if (!this.isOpen) {
      return;
    }

    this.isOpen = false;
    const events = Object.keys(this.listeners);

    if (events.length) {
      for (const event of events) {
        for (const callback of this.listeners[event]) {
          this.unListen(event, callback);
        }
      }
    }

    this.connection.closeChannel(this);
  };

  isClosed(): boolean {
    return !this.isOpen;
  }

}

export default EchoConnectionChannel;
