// @flow

import React, {
  useCallback,
  useEffect,
  useState,
  lazy,
  Suspense,
} from 'react';

import { FetchProductThunk } from './product/thunk/fetchProduct.thunk';
import Spinner from './ui/components/spinner';
import { initLocales } from './ui/localization/i18n';

import s from './start.style';

const PRODUCTS = {
  bot: {
    Component: lazy(() => import('./product/bot/app')),
    configure: async () => {
      const configure = await import(('./product/bot/preAppConfigure'));
      configure?.default?.();
    },
  },
  knowledge: {
    Component: lazy(() => import('./product/knowledge/app')),
    configure: async () => {
      const configure = await import(('./product/knowledge/preAppConfigure'));
      configure?.default?.();
    },
  },
};

function getProductApp(productName: string) {
  return PRODUCTS[productName]?.Component || null;
}

const runConfigure = async (productName: string) => PRODUCTS[productName]?.configure?.();

export const Start = () => {
  const [product, setProduct] = useState<string>(null);
  const [localesLoaded, setLocalesLoaded] = useState<boolean>(false);
  const [appConfigured, setAppConfigured] = useState<boolean>(false);

  const configureApp = useCallback(async (productName: string) => {
    setAppConfigured(false);
    await runConfigure(productName);
    setAppConfigured(true);
  }, []);

  useEffect(() => {
    const productThunk = new FetchProductThunk();
    productThunk.invoke().then(productName => {
      setProduct(productName);
    });
  }, []);

  useEffect(() => {
    if (!product) {
      return;
    }

    initLocales(product).then(() => {
      setLocalesLoaded(true);
    });
  }, [product]);

  useEffect(() => {
    if (!localesLoaded || !product) {
      return;
    }

    configureApp(product);

  }, [localesLoaded, product]);

  const renderLoader = () => (
    <s.Spinner>
      <Spinner />
    </s.Spinner>
  );

  if (!appConfigured || !product) {
    return renderLoader();
  }

  const App = getProductApp(product);

  return (
    <Suspense fallback={renderLoader()}>
      <App />
    </Suspense>
  );
};

export default Start;
