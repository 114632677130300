// @flow

export const KEYBOARD_KEYS = {
  ENTER: 13,
  UP: 38,
  DOWN: 40,
  ESC: 27,
};

export default { KEYBOARD_KEYS };
