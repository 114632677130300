// @flow
import _uniq from 'lodash/uniq';
import _compact from 'lodash/compact';

export const ROOT_CLASS_PREFIX = 'form-one';

export function classBem(components: string[], state: string | null = null, root: string = ROOT_CLASS_PREFIX) {
  const component = _uniq(_compact([root, ...components])).join('__');
  const className = _compact([component, state]).join('--');
  return className;
}

export default classBem;
